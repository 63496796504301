<template>
  <div>{{ data }}</div>
</template>

<script>
export default {
  data() {
    return {
      data: '您好'
    }
  }
}
</script>

<style>

</style>